import React from 'react';
import Container from 'components/commercetools-ui/atoms/container';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import { FooterLink } from './../footer/atoms/column';

export interface Props {
  copyright: string;
  privacy: FooterLink[];
}

const FooterAccount: React.FC<Props> = ({ copyright, privacy }) => {
  const [{ name, reference }] = privacy;

  return (
    <footer aria-labelledby="footer-heading" className="w-full bg-neutral-6">
      <Container>
        <div className="flex flex-col gap-10 p-20 text-neutral-5 md:flex-row md:justify-between md:px-46 md:pb-19">
          <Typography className="text-12 leading-[15px]">{copyright}</Typography>
          <Link variant="primary" link={reference}>
            <Typography className="text-12 font-bold leading-[15px] text-neutral-5">{name}</Typography>
          </Link>
        </div>
      </Container>
    </footer>
  );
};

export default FooterAccount;
